<template>
    <div>
        <CRow>
            <CCol col="12">
                <CCard>
                    <CCardHeader>Gönderilen E-posta</CCardHeader>
                    <CCardBody>
                        <CForm>
                            <CInput
                                label="Konu"
                                horizontal
                                :value.sync="item.subject"
                                disabled="true"
                            />
                            <CInput
                                label="Başarılı Gönderim Sayısı"
                                horizontal
                                :value.sync="item.successCount"
                                disabled="true"
                            />
                            <CInput
                                label="Başarısız Gönderim Sayısı"
                                horizontal
                                :value.sync="item.failedCount"
                                disabled="true"
                            />
                            
                            <h2>İçerik</h2>
                            <div v-html="item.content"></div>
                        </CForm>
                    </CCardBody>
                    <CCardFooter>
                        <CButton
                            type="button"
                            size="sm"
                            color="primary"
                            v-on:click="retryFailed()"
                        >
                            <CIcon name="cil-check-circle"/> Hatalı olanlara tekrar gönder
                        </CButton>
                    </CCardFooter>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>

<script>
import axios from 'axios';
import { VueEditor } from 'vue2-editor';
import { getProductCategories } from '../../utils';


export default {
    name: 'MailTemplateDetail',

    components: {
        VueEditor
    },

    data () {
        return {
            item: {
                name: '',
                description: '',
                subject: '',
                content: '' 
            },
            alert: {
                isOpen: false,
                color: '',
                message: ''
            },
        }
    },

    methods: {
        closeAlert() { this.alert.isOpen = false; },

        async retryFailed() {
            try {
                const response = await axios({
                    method: 'post',
                    url: `/mail/sended-mails/${this.$route.params.id}/resend-faileds`,
                    data: {}
                });

                if (response.status === 201) {
                    this.alert = {
                        isOpen: true,
                        color: 'success',
                        message: 'Gönderim başlatıldı!'
                    };
                }
            } catch (error) {
                console.log(error)
                this.alert = {
                    isOpen: true,
                    color: 'danger',
                    message: 'İşlem gerçekleştirilirken bir hata oluştu'
                };
            }
        },
    },

    async mounted() {
        const response = await axios({
            method: 'get',
            url: `/mail/sended-mails/${this.$route.params.id}`
        });

        this.item = response.data;
    }
}
</script>

<style lang="scss" scoped>
    label {
        margin-bottom: 15px;
    }
</style>
